import React from 'react'
import { Flex, Box, Text, Heading, Container } from '@chakra-ui/react'
import { useRSVPFiltered } from '../../../hooks/useRSVPs'
import { Helmet } from 'react-helmet'
import Card from '../../atoms/Card'
import RSVPStatusFilter from '../InviteList/atoms/RSVPStatusFilter'
import FormBroadcastMessage from './atoms/FormBroadcastMessage'
import NavBar from '../../atoms/NavBar'
import BackButton from '../../atoms/nav-controls/BackButton'
import { useNavigate } from 'react-router-dom'
import FooterBase from '../../molecules/FooterBase'

const Broadcast = () => {
	const { rsvps, collection } = useRSVPFiltered()
	const navigate = useNavigate()

	// Won't be needed once we start broadcasting via SMS
	const emailRsvps = rsvps.filter(
		(obj) => obj.deliveryEmail !== undefined && obj.deliveryEmail !== '' && obj.deliveryEmail !== null
	)
	const emailCollection = collection.filter(
		(obj) => obj.deliveryEmail !== undefined && obj.deliveryEmail !== '' && obj.deliveryEmail !== null
	)

	return (
		<>
			<Helmet>
				<title>VidDay - Broadcast a Message</title>
			</Helmet>

			<NavBar>
				<Flex>
					<BackButton onClick={() => navigate(-1)} />
				</Flex>
			</NavBar>

			<Container maxW="container.md" py="1rem">
				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Broadcast a Message
					</Heading>
					<Text mb="1rem">Message people on your list.</Text>
				</Box>
				<Card p={['1rem', '2rem']} mb="2rem" alignItems="center">
					{emailRsvps.length > 0 ? (
						<>
							<Text>Use the filters to choose who you want to message.</Text>
							<Text fontSize="sm" mb="1rem">
								Only users invited via email will be messaged.
							</Text>
							<RSVPStatusFilter rsvps={emailRsvps} mb="2rem" hideRemoved />
							<FormBroadcastMessage collection={emailCollection} />
						</>
					) : (
						<>
							<Text mb="1rem">
								None of your have an email address. RSVP's must have emails to broadcast to.
							</Text>
						</>
					)}
				</Card>
			</Container>
			<FooterBase />
		</>
	)
}

export default Broadcast
