import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useEventContext } from '../../../../contexts/EventProvider'
import { Button, chakra, Flex, Heading, VStack } from '@chakra-ui/react'
import Textarea from '../../../molecules/controls/Textarea'
import { broadcastMessage } from '../../../../../api/app/behaviors/current-event'
import CheckExMark from '../../../atoms/CheckExMark'
import { SendRounded } from '../../../atoms/Icon'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import { useNavigate } from 'react-router-dom'
import redirectToNextIfWindow from '../../../../utils/redirectToNextIfWindow'

const FormBroadcastMessage = ({ collection, ...rest }) => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const minChars = 60
	const maxChars = 1000
	// adding this as a temp measure to resolve count issue with newlines.
	const tempRealMaxChars = 1100

	const [message, setMessage] = useState(false)
	const [charCount, setCharCount] = useState(0)
	const [messageSent, setMessageSent] = useState(false)

	const handleOnChange = (e) => {
		let v = e?.currentTarget?.value
		setCharCount(v.length)
		setMessage(v.replace(/\n/g, '<br/>'))
		setValue('message', v.replace(/\n/g, '<br/>'))
	}

	/** Submit form */
	const handleOnSubmit = () => {
		if (collection.length > 0) {
			var userArray = collection.map((rsvp) => {
				return {
					email: rsvp.deliveryEmail,
					name: String(`${rsvp.firstName} ${rsvp.lastName ? rsvp.lastName : ''}`).trim(),
					firstName: rsvp.firstName || '',
					lastName: rsvp.lastName || '',
					id: rsvp.userId,
				}
			})
			const messageData = { message: message, userArray: userArray, eventId: event.id }
			// logger.silly(`COLLECTION: {}`, messageData)
			dispatch({ type: 'BROADCAST_MESSAGE', data: messageData })
			dispatch(broadcastMessage(messageData))
			setMessageSent(true)
		}
	}

	const schemaBroadcastMessage = Yup.object().shape({
		message: Yup.string()
			.required()
			// .matches(/^[a-z0-9]+$/, "Must be safe characters only")
			.min(minChars, `Must be more than ${minChars} characters`)
			.max(tempRealMaxChars, `Must be less than ${maxChars} characters`),
	})
	const { control, errors, handleSubmit, setValue } = useForm({
		resolver: yupResolver(schemaBroadcastMessage),
	})

	const disabled = charCount > maxChars || collection.length < 1

	return (
		<>
			{messageSent ? (
				<VStack pb="2rem" justify="center">
					<VStack py="3rem">
						<CheckExMark render={true} check={true} scale={0.6} />
						<Heading as="h2" textAlign="center" size="md">
							The carrier pigeons are off!
						</Heading>
					</VStack>
					<Button
						size="sm"
						onClick={() => {
							// navigate(handlers.invitation_list)
							redirectToNextIfWindow(handlers.invitation_list)
						}}
						minWidth="5rem">
						Back
					</Button>
				</VStack>
			) : (
				<chakra.form w="full" onSubmit={handleSubmit(handleOnSubmit)} {...rest}>
					<Textarea
						control={control}
						errors={errors}
						minH={['240px', '340']}
						label=""
						placeholder="Type your message here"
						name="message"
						onChange={(e) => handleOnChange(e)}
						charCount={charCount}
						maxChar={maxChars}
					/>
					<Flex justify="center">
						<Button type="submit" disabled={disabled} mt={['1rem', '2rem']} variant="solid">
							Send Message to {collection.length} contact(s)
							<SendRounded ms=".5rem" />
						</Button>
					</Flex>
				</chakra.form>
			)}
		</>
	)
}

export default FormBroadcastMessage
