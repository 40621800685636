import React from 'react'
import { FormErrorMessage } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { FormLabel, FormHelperText, FormControl, Textarea } from '@chakra-ui/react'

const ControlledTextarea = (props) => {
	const {
		control,
		name,
		label,
		placeholder,
		helperText,
		errors,
		defaultValue,
		readOnly,
		charCount,
		maxChar,
		onChange,
		onBlur,
		...rest
	} = props

	let isError, errorMessage
	// Proceed as a normal (non-nested) input
	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}
	if (charCount > maxChar) {
		isError = true
		errorMessage = 'Over character limit'
	}

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props) => {
				return (
					<FormControl isInvalid={isError}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}

						<Textarea
							maxH="fit-content"
							resize="vertical"
							paddingBottom="2.25rem"
							disabled={readOnly}
							id={name}
							placeholder={placeholder}
							ref={props.ref}
							defaultValue={props.value}
							onChange={onChange}
							onBlur={onBlur}
							{...rest}
							// {...props}
						/>

						{charCount != null && (
							<FormHelperText
								textAlign="right"
								float="right"
								marginTop="-2rem"
								marginRight="1rem"
								color={charCount > maxChar ? 'red.500' : 'inherit'}>
								{charCount}
								{maxChar && `/${maxChar}`}
							</FormHelperText>
						)}

						{isError ? (
							<FormErrorMessage display="block" textAlign="left">
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledTextarea
